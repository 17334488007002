<template>
  <div>
    <h6> {{ title }}</h6>
    <div
      id="my-superset-container"
      style="width:100%; min-height:100vh;"
    />
  </div>
</template>

<script>

export default {
  props: {
    title: { type: String, default: 'MOH 706' },
    dashboardId: { type: String, default: '35' },
    embedId: { type: String, default: 'f63ce406-8b13-40f9-8a69-5f551b1e7035' },
  },
  mounted() {
    // Check if the supersetEmbeddedSdk SDK is already defined
    if (typeof window.supersetEmbeddedSdk === 'undefined') {
      // Load the supersetEmbeddedSdk SDK dynamically
      const script = document.createElement('script')
      script.src = 'https://unpkg.com/@superset-ui/embedded-sdk'
      script.onload = () => {
        this.embedSupersetDashboard()
      }
      document.head.appendChild(script)
    } else {
      this.embedSupersetDashboard()
    }
  },
  methods: {
    async embedSupersetDashboard() {
      const { message } = await this.api('clinical.api.superset.dashboard.authenticate', { dashboard_id: this.dashboardId })
      if (typeof window.supersetEmbeddedSdk !== 'undefined') {
        // Access the supersetEmbeddedSdk object from the global scope
        console.log('Embedding')
        const { supersetEmbeddedSdk } = window

        // Your Superset dashboard embed configuration
        const dashboardEmbed = await supersetEmbeddedSdk.embedDashboard({
          id: this.embedId, // given by the Superset embedding UI
          supersetDomain: 'http://102.220.22.165',
          mountPoint: document.getElementById('my-superset-container'), // any html element that can contain an iframe
          fetchGuestToken: () => message,
          dashboardUiConfig: {

            hideTitle: true,
            filters: {
              expanded: false,
            },
          },
        })

        console.log('Dash', dashboardEmbed)
      } else {
        console.error('supersetEmbeddedSdk SDK is still not defined.')
      }
    },

  },
}
</script>
<style>
iframe {
    min-height: 85vh !important;
    width: 100% !important;
}
</style>
