<template>
  <MOH
    title="MOH 705"
    :dashboard-id="'29'"
    :embed-id="'64dbb162-f956-4f94-ae4d-7c692f3a083b'"
  />
</template>
<script>
import MOH from './MOH706.vue'

export default {
  components: {
    MOH,
  },

}
</script>
